<template>
  <section>
    <h3>{{ title }}</h3>
    <div>
      <prismic-link
        :link="foundingPartner.link"
        v-for="(foundingPartner, index) in foundingPartners"
        :key="`foundingPartner${index}`"
      >
        <img :src="foundingPartner.logo.url" :alt="foundingPartner.title" />
      </prismic-link>
    </div>
  </section>
</template>

<script>
import PrismicLink from "@/components/PrismicLink";

export default {
  name: "FoundingPartners",
  components: {
    PrismicLink
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    foundingPartners: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  margin: 120px 0;

  @include mobile {
    margin: 64px 0;
  }

  h3 {
    text-align: center;
  }

  > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    > .prismic-link {
      width: 20%;
      padding: 2% 0;
      margin: 12px;
      background-color: $purple2;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;

      @include tablet {
        width: 40%;
        min-height: 200px;
        margin: 8px;
      }

      @include mobile {
        width: 40%;
        min-height: 130px;
      }

      img {
        width: 60%;
        max-height: 55%;
      }
    }
  }
}
</style>
