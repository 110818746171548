import Vue from "vue";
import Router from "vue-router";
import NotFound from "@/pages/NotFound.vue";
import Template from "@/pages/Template";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Template,
      props: {
        uid: "landing"
      }
    },
    {
      path: "/not-found",
      name: "not-found",
      component: NotFound
    },
    {
      path: "/article/:uid",
      component: Template,
      props: true
    },
    {
      path: "/:uid",
      component: Template,
      props: true
    }
  ]
});
