<template>
  <footer>
    <div>
      <div class="logo">
        <img src="~@/assets/images/AMR-logo.png" alt="Logo" />
      </div>
      <div class="partners">
        <span v-if="content.founding_partners_copy">{{
          content.founding_partners_copy[0].text
        }}</span>
        <div>
          <prismic-link
            :link="foundingPartner.link"
            v-for="(foundingPartner, index) in content.founding_partners.map(
              obj => obj.founding_partner
            )"
            :key="`partner${index}`"
          >
            <img
              :src="foundingPartner.logo.url"
              :alt="foundingPartner.name[0].text"
            />
          </prismic-link>
        </div>
      </div>
    </div>
    <div>
      <div class="socials">
        <prismic-link
          :link="social.link"
          v-for="(social, index) in content.socials"
          :key="`social${index}`"
        >
          <span>{{ social.title[0].text }}</span>
          <img :src="social.icon.url" :alt="social.title[0].text" />
        </prismic-link>
      </div>
      <div v-if="content.copyright" class="copyright">
        {{ content.copyright[0].text }}
      </div>
    </div>
  </footer>
</template>

<script>
import PrismicLink from "@/components/PrismicLink";
import gql from "graphql-tag";

export default {
  name: "AppFooter",
  components: {
    PrismicLink
  },
  data() {
    return {
      content: {
        founding_partners: []
      }
    };
  },
  apollo: {
    content: {
      query: gql`
        query {
          footer(uid: "footer", lang: "en-gb") {
            founding_partners_copy
            founding_partners {
              ... on FooterFounding_partners {
                founding_partner {
                  ... on Founding_partner {
                    link {
                      ... on Article {
                        _meta {
                          uid
                        }
                      }
                      ... on _ExternalLink {
                        url
                        target
                      }
                    }
                    name
                    logo {
                      ... on _ImageLink {
                        url
                      }
                    }
                  }
                }
              }
            }
            socials {
              title
              link {
                ... on _ExternalLink {
                  url
                }
              }
              icon {
                ... on _ImageLink {
                  url
                }
              }
            }
            copyright
          }
        }
      `,
      update: data => data.footer
    }
  }
};
</script>

<style lang="scss" scoped>
footer {
  width: 100%;
  padding: 72px 162px;
  background-color: $purple3;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  font-family: $font-secondary;

  @include tablet {
    padding: 40px 48px;
  }

  > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mobile {
      width: 100%;
    }

    .logo {
      display: flex;
      margin-bottom: 40px;

      @include mobile {
        justify-content: center;
      }

      img {
        height: 67px;
      }
    }

    .partners {
      @include mobile {
        text-align: center;
        margin-bottom: 40px;
      }

      span {
        display: block;
        margin-bottom: 12px;

        @include mobile {
          margin-bottom: 24px;
        }
      }

      div {
        display: flex;
        flex-wrap: wrap;

        .prismic-link {
          width: 25%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 80%;
            max-height: 60%;

            @include mobile {
              width: 50%;
              max-height: 75%;
            }
          }

          @include tablet {
            width: 50%;
          }
        }
      }
    }

    .socials {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      font-size: rem-calc(18);
      font-weight: bold;

      @include mobile {
        justify-content: space-evenly;
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-left: 40px;
        margin-bottom: 12px;
        white-space: nowrap;

        @include mobile {
          margin-left: 0;
        }

        img {
          width: 20px;
          height: 20px;
          padding: 10px;
          background-color: $purple2;
          border-radius: 50%;
          margin-left: 16px;
        }
      }
    }

    .copyright {
      font-weight: normal;
      font-size: rem-calc(14);
      text-align: right;

      @include mobile {
        margin-top: 40px;
        text-align: center;
      }
    }
  }
}
</style>
