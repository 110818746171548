<template>
  <section>
    <h3 v-if="content.title">{{ content.title[0].text }}</h3>
    <a
      class="twitter-timeline"
      :href="`https://twitter.com/${content.handle}`"
      data-chrome="noheader nofooter transparent"
      data-theme="dark"
      data-tweet-limit="3"
      data-width="300"
      >Tweets by {{ content.handle }}</a
    >
  </section>
</template>

<script>
export default {
  name: "TwitterTimeline",
  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    let script = document.createElement("script");
    script.setAttribute("src", "https://platform.twitter.com/widgets.js");
    document.head.appendChild(script);
  }
};
</script>

<style lang="scss" scoped>
section {
  h3 {
    text-align: center;
  }
}
</style>
