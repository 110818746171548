<template>
  <section>
    <h3>{{ title }}</h3>
    <div>
      <prismic-link
        :link="investorPartner.article || investorPartner.link"
        v-for="(investorPartner, index) in investorPartners"
        :key="`investorPartner${index}`"
      >
        <img :src="investorPartner.logo.url" :alt="investorPartner.title" />
      </prismic-link>
    </div>
  </section>
</template>

<script>
import PrismicLink from "@/components/PrismicLink";

export default {
  name: "InvestorPartners",
  components: {
    PrismicLink
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    investorPartners: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  margin: 120px 0;

  @include mobile {
    margin: 64px 0;
  }

  h3 {
    text-align: center;
  }

  > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    > .prismic-link {
      width: 12%;
      margin: 12px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      overflow: hidden;

      @include tablet {
        width: 25%;
        margin: 8px;
      }

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}
</style>
