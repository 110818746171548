<template>
  <article
    v-if="!$apollo.loading"
    :class="{
      white: content.white_background,
      'has-image': content.body && content.body[0].primary.header_background
    }"
  >
    <prismic-slices
      :uid="uid"
      :query-name="this.$route.path.startsWith('/article') ? 'article' : 'page'"
    />
  </article>
</template>

<script>
import PrismicSlices from "@/components/PrismicSlices";
import gql from "graphql-tag";

export default {
  components: {
    PrismicSlices
  },
  props: {
    uid: {
      type: String,
      default: ""
    }
  },
  metaInfo() {
    return {
      title:
        this.content.body && this.content.body[0].primary.header_title
          ? this.content.body[0].primary.header_title[0].text
          : null
    };
  },
  data() {
    return {
      content: {}
    };
  },
  apollo: {
    content: {
      query: gql`
        query ($uid: String!) {
          article(uid: $uid, lang: "en-gb") {
            white_background
            body {
              ... on ArticleBodyStandard_header {
                primary {
                  header_background {
                    ... on _ImageLink {
                      url
                    }
                  }
                  header_title
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          uid: this.uid
        };
      },
      skip() {
        return !this.uid || !this.$route.path.startsWith("/article");
      },
      update: data => data.article
    }
  },
  watch: {
    content() {
      if (!this.content) {
        this.$router.push("/not-found");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
article {
  position: relative;

  &.white {
    background-color: white;

    ::v-deep {
      > div {
        > :not(.standard-header) {
          > *,
          > ul li,
          > p a,
          > ul li a {
            color: $purple2 !important;
          }

          li::before {
            background-color: $purple2;
          }

          ol li {
            margin-bottom: 8px;
            strong:last-child {
              display: block;
              margin-left: -40px;
              margin-top: 16px;
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
