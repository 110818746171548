<template>
  <section>
    <div class="controls" v-if="showControls">
      <app-button
        @click.native="scroll('left')"
        :disabled="isScrollLeftDisabled"
      >
        <img src="~@/assets/icons/arrow.svg" alt="<-" />
      </app-button>
      <app-button
        @click.native="scroll('right')"
        :disabled="isScrollRightDisabled"
      >
        <img src="~@/assets/icons/arrow.svg" alt="->" />
      </app-button>
    </div>
    <h3>{{ title }}</h3>
    <div
      class="updates-container"
      ref="updatesContainer"
      @scroll="manualScroll"
    >
      <prismic-link
        :link="docLink(obj.update)"
        v-for="(obj, index) in updates"
        :key="`update${index}`"
        :class="{ feature: !!docBackground(obj.update) }"
        :style="{
          backgroundImage: docBackground(obj.update)
            ? `url(${docBackground(obj.update)})`
            : null
        }"
      >
        <div>
          <div class="top">
            <div class="tag" v-if="docTag(obj.update)">
              {{ docTag(obj.update) }}
            </div>
            <div
              class="date"
              v-if="docDate(obj.update) && docBackground(obj.update)"
            >
              {{ formatDate(docDate(obj.update)) }}
            </div>
          </div>
          <div>
            <img
              v-if="docThumbnail(obj.update)"
              :src="docThumbnail(obj.update)"
              alt=""
            />
            <h3 v-if="docTitle(obj.update)">
              {{ docTitle(obj.update) }}
            </h3>
          </div>
        </div>
        <div class="byline" v-if="!docBackground(obj.update)">
          <div class="partner" v-if="obj.update.partner">
            {{ obj.update.partner.name[0].text }}
          </div>
          <div class="date" v-if="docDate(obj.update)">
            {{ formatDate(docDate(obj.update)) }}
          </div>
        </div>
      </prismic-link>
    </div>
  </section>
</template>

<script>
import AppButton from "@/components/AppButton";
import FormatMixin from "@/mixins/Format";
import PrismicCardMixin from "@/mixins/PrismicCard";
import PrismicLink from "@/components/PrismicLink";

export default {
  name: "UpdatesCarousel",
  components: {
    AppButton,
    PrismicLink
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    updates: {
      type: Array,
      default: () => []
    }
  },
  mixins: [FormatMixin, PrismicCardMixin],
  data() {
    return {
      currentScrollPosition: null,
      isScrollLeftDisabled: false,
      isScrollRightDisabled: false,
      showControls: false
    };
  },
  methods: {
    scroll(direction = "left") {
      if (this.$refs.updatesContainer) {
        let updateWidth =
          this.$refs.updatesContainer.firstElementChild.clientWidth + 24;

        if (direction == "left") {
          updateWidth = updateWidth * -1;
        }

        this.currentScrollPosition = this.currentScrollPosition + updateWidth;

        this.$refs.updatesContainer.scrollLeft = this.currentScrollPosition;
      }
    },
    checkDisabled() {
      if (this.$refs.updatesContainer) {
        this.isScrollLeftDisabled = this.currentScrollPosition <= 0;

        this.isScrollRightDisabled =
          this.currentScrollPosition >=
          this.$refs.updatesContainer.scrollLeftMax;
      }
    },
    manualScroll() {
      this.currentScrollPosition = this.$refs.updatesContainer.scrollLeft;

      this.checkDisabled();
    },
    checkControls() {
      if (this.$refs.updatesContainer) {
        this.showControls =
          this.$refs.updatesContainer.scrollWidth >
          this.$refs.updatesContainer.clientWidth;
      }
    }
  },
  mounted() {
    this.currentScrollPosition = this.$refs.updatesContainer.scrollLeft;

    this.checkDisabled();

    this.checkControls();
  }
};
</script>

<style lang="scss" scoped>
section {
  margin: 120px 0;
  position: relative;

  @include mobile {
    margin: 64px 0;
  }

  h3 {
    text-align: center;
  }

  .controls {
    position: absolute;
    right: 48px;

    @include mobile {
      display: none;
    }

    button {
      margin-left: 16px;
      padding: 14px;
      line-height: initial;

      img {
        width: 20px;
        height: 20px;
        display: block;
      }

      &:first-child {
        img {
          transform: rotateZ(180deg);
        }
      }

      &:hover {
        top: 0;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
      }

      &:disabled {
        opacity: 0.4;
      }
    }
  }

  .updates-container {
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    margin-left: 48px;
    padding-right: 48px;
    padding-bottom: 24px;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    // 2005 rang it wants its css back
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      width: 30%;
      min-width: 30%;
      min-height: 284px;
      margin-right: 24px;
      border-radius: 8px;
      background-color: $purple2;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      scroll-snap-align: start;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      border-top: 8px solid $purple2; // Fixes half-pixel rendering issue with border-radius

      @include tablet {
        width: 50%;
        min-width: 50%;
      }

      @include mobile {
        width: 100%;
        min-width: 100%;
      }

      > div {
        padding: 16px;

        &:first-child {
          padding-top: 8px; // Fixes half-pixel rendering issue with border-radius (see above)
        }
      }

      &.feature {
        background-color: transparent;

        > div:first-child {
          background: linear-gradient(
            to bottom,
            $purple2 0%,
            rgba($purple2, 0.4) 100%
          );
        }
      }

      .top {
        display: flex;
        justify-content: space-between;

        .tag {
          text-transform: uppercase;
          color: $green1;
          font-weight: bold;
          letter-spacing: rem-calc(2);
        }
      }

      h3 {
        margin: 8px 0;
        text-align: left;
        font-size: rem-calc(20);
        font-weight: 600;
      }

      img {
        float: right;
        width: 80px;
        border-radius: 4px;
        margin-left: 12px;
        margin-bottom: 12px;
      }

      .byline {
        display: flex;

        > * {
          margin-right: 18px;
        }
      }

      .partner,
      .date {
        font-weight: bold;
      }
    }
  }
}
</style>
