<template>
  <transition name="fade" mode="out-in" appear>
    <div v-if="!$apollo.loading">
      <template v-for="(section, index) in content.body">
        <landing-header
          :key="`section${index}`"
          v-if="section.__typename == `${queryNameCap}BodyLanding_header`"
          :content="section.primary || {}"
          :founding-partners="section.fields.map(obj => obj.founding_partner)"
        />
        <standard-header
          :key="`section${index}`"
          v-if="section.__typename == `${queryNameCap}BodyStandard_header`"
          :content="section.primary || {}"
        />
        <register-header
          :key="`section${index}`"
          v-if="section.__typename == `${queryNameCap}BodyRegister_header`"
          :content="section.primary || {}"
        />
        <prismic-text
          :key="`section${index}`"
          v-if="section.__typename == `${queryNameCap}BodyText`"
          :content="section"
        />
        <founding-partners
          :key="`section${index}`"
          v-if="section.__typename == `${queryNameCap}BodyFounding_partners`"
          :title="section.primary.founding_partners_title[0].text"
          :founding-partners="section.fields.map(obj => obj.founding_partner)"
        />
        <investor-partners
          :key="`section${index}`"
          v-if="section.__typename == `${queryNameCap}BodyInvestor_partners`"
          :title="section.primary.investor_partners_title[0].text"
          :investor-partners="section.fields.map(obj => obj.investor_partner)"
        />
        <video-gallery
          :key="`section${index}`"
          v-if="section.__typename == `${queryNameCap}BodyVideo_gallery`"
          :title="section.primary.video_gallery_title[0].text"
          :videos="section.fields"
        />
        <updates-carousel
          :key="`section${index}`"
          v-if="section.__typename == `${queryNameCap}BodyUpdates_carousel`"
          :title="section.primary.updates_carousel_title[0].text"
          :updates="section.fields"
        />
        <promo
          :key="`section${index}`"
          v-if="section.__typename == `${queryNameCap}BodyPromo`"
          :title="section.primary.promo_title[0].text"
          :subtitle="section.primary.promo_subtitle[0].text"
          :button-link="section.primary.promo_button_link"
          :button-text="section.primary.promo_button_text[0].text"
          :background-url="(section.primary.promo_background || {}).url"
        />
        <hub
          :key="`section${index}`"
          v-if="section.__typename == `${queryNameCap}BodyHub`"
          :tags="section.fields.map(tag => tag.hub_tag)"
          :uid="uid"
        />
        <twitter-timeline
          :key="`section${index}`"
          v-if="section.__typename == `${queryNameCap}BodyTwitter_timeline`"
          :content="section.primary"
        />
      </template>
    </div>
  </transition>
</template>

<script>
import Container from "@/components/Container";
import Hub from "@/components/Hub";
import InvestorPartners from "@/components/InvestorPartners";
import LandingHeader from "@/components/LandingHeader";
import FoundingPartners from "@/components/FoundingPartners";
import PrismicText from "@/components/PrismicText";
import Promo from "@/components/Promo";
import RegisterHeader from "@/components/RegisterHeader";
import StandardHeader from "@/components/StandardHeader";
import TwitterTimeline from "@/components/TwitterTimeline";
import UpdatesCarousel from "@/components/UpdatesCarousel";
import VideoGallery from "@/components/VideoGallery";
import gql from "graphql-tag";

export default {
  name: "PrismicBody",
  components: {
    Container,
    FoundingPartners,
    Hub,
    InvestorPartners,
    LandingHeader,
    PrismicText,
    Promo,
    RegisterHeader,
    StandardHeader,
    TwitterTimeline,
    UpdatesCarousel,
    VideoGallery
  },
  props: {
    uid: {
      type: String,
      default: ""
    },
    queryName: {
      type: String,
      default: "article"
    }
  },
  data() {
    return {
      content: {}
    };
  },
  computed: {
    queryNameCap() {
      return this.queryName.charAt(0).toUpperCase() + this.queryName.slice(1);
    }
  },
  apollo: {
    content: {
      query() {
        return gql`
          query($uid: String!) {
            ${this.queryName}(uid: $uid, lang: "en-gb") {
              body {
                ... on ${this.queryNameCap}BodyStandard_header {
                  primary {
                    header_tag
                    header_title
                    header_subtitle
                    header_background {
                      ... on _ImageLink {
                        url
                      }
                    }
                    header_logo {
                      ... on _ImageLink {
                        url
                      }
                    }
                    author_name
                    author_link {
                      ... on Article {
                        _meta {
                          uid
                        }
                      }
                      ... on _ExternalLink {
                        url
                        target
                      }
                    }
                    publish_date
                  }
                }
                ... on ${this.queryNameCap}BodyRegister_header {
                  primary {
                    header_tag
                    header_title
                    header_subtitle
                  }
                }
                ... on ${this.queryNameCap}BodyLanding_header {
                  primary {
                    header_tag
                    header_title
                    header_subtitle
                    header_background {
                      ... on _ImageLink {
                        url
                      }
                      ... on _FileLink {
                        url
                      }
                    }
                  }
                  fields {
                    founding_partner {
                      ... on Founding_partner {
                        link {
                          ... on Article {
                            _meta {
                              uid
                            }
                          }
                          ... on _ExternalLink {
                            url
                            target
                          }
                        }
                        name
                        logo {
                          ... on _ImageLink {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                ... on ${this.queryNameCap}BodyText {
                  primary {
                    text
                  }
                }
                ... on ${this.queryNameCap}BodyFounding_partners {
                  primary {
                    founding_partners_title
                  }
                  fields {
                    founding_partner {
                      ... on Founding_partner {
                        link {
                          ... on Article {
                            _meta {
                              uid
                            }
                          }
                          ... on _ExternalLink {
                            url
                            target
                          }
                        }
                        name
                        logo {
                          ... on _ImageLink {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                ... on ${this.queryNameCap}BodyInvestor_partners {
                  primary {
                    investor_partners_title
                  }
                  fields {
                    investor_partner {
                      ... on Investor_partner {
                        link {
                          ... on Article {
                            _meta {
                              uid
                            }
                          }
                          ... on _ExternalLink {
                            url
                            target
                          }
                        }
                        article {
                          ... on Article {
                            _meta {
                              uid
                            }
                          }
                        }
                        name
                        logo {
                          ... on _ImageLink {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                ... on ${this.queryNameCap}BodyUpdates_carousel {
                  primary {
                    updates_carousel_title
                  }
                  fields {
                    update {
                      ... on Update {
                        type
                        date
                        author {
                          ... on Founding_partner {
                            link {
                              ... on Article {
                                _meta {
                                  uid
                                }
                              }
                              ... on _ExternalLink {
                                url
                                target
                              }
                            }
                            name
                            logo {
                              ... on _ImageLink {
                                url
                              }
                            }
                          }
                        }
                        headline
                        link {
                          ... on Article {
                            _meta {
                              uid
                            }
                          }
                          ... on _ExternalLink {
                            url
                            target
                          }
                        }
                        background {
                          ... on _ImageLink {
                            url
                          }
                        }
                        thumbnail {
                          ... on _ImageLink {
                            url
                          }
                        }
                      }
                      ... on Article {
                        _meta {
                          uid
                          lastPublicationDate
                        }
                        body {
                          ... on ArticleBodyStandard_header {
                            primary {
                              header_title
                              header_background {
                                ... on _ImageLink {
                                  url
                                }
                              }
                              header_logo {
                                ... on _ImageLink {
                                  url
                                }
                              }
                              author_name
                              publish_date
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on ${this.queryNameCap}BodyVideo_gallery {
                  primary {
                    video_gallery_title
                  }
                  fields {
                    video {
                      ... on Video {
                        video
                      }
                    }
                  }
                }
                ... on ${this.queryNameCap}BodyPromo {
                  primary {
                    promo_title
                    promo_subtitle
                    promo_button_link {
                      ... on Article {
                        _meta {
                          uid
                        }
                      }
                      ... on Page {
                        _meta {
                          uid
                        }
                      }
                      ... on _ExternalLink {
                        url
                        target
                      }
                    }
                    promo_button_text
                    promo_background {
                      ... on _ImageLink {
                        url
                      }
                    }
                  }
                }
                ... on ${this.queryNameCap}BodyHub {
                  fields {
                    hub_tag
                  }
                }
                ... on ${this.queryNameCap}BodyTwitter_timeline {
                  primary {
                    title
                    handle
                  }
                }
              }
            }
          }
        `;
      },
      variables() {
        return {
          uid: this.uid
        };
      },
      skip() {
        return !this.uid;
      },
      update(data) {
        return data[this.queryName];
      }
    }
  }
};
</script>
