<template>
  <header>
    <router-link to="/">
      <img src="~@/assets/images/AMR-logo.png" alt="Logo" />
    </router-link>
    <nav :class="{ open: navOpen }" @click="navOpen = !navOpen">
      <prismic-link
        v-for="(nav, index) in content.navigation"
        :key="`nav${index}`"
        :link="nav.navigation_link"
        :class="{
          active:
            nav.navigation_link._meta &&
            $route.path == `/${nav.navigation_link._meta.uid}`
        }"
        >{{ nav.navigation_text }}</prismic-link
      >
    </nav>
    <prismic-link
      v-if="content.button_link && content.button_text"
      :link="content.button_link"
      class="no-highlight"
    >
      <app-button>{{ content.button_text[0].text }}</app-button>
    </prismic-link>
    <span @click="navOpen = !navOpen">
      <img v-if="!navOpen" src="~@/assets/icons/hamburger.svg" alt="Nav" />
      <img v-else src="~@/assets/icons/close.svg" alt="Close" />
    </span>
  </header>
</template>

<script>
import AppButton from "@/components/AppButton";
import PrismicLink from "@/components/PrismicLink";
import gql from "graphql-tag";

export default {
  name: "AppHeader",
  components: {
    AppButton,
    PrismicLink
  },
  data() {
    return {
      navOpen: false,
      content: {}
    };
  },
  apollo: {
    content: {
      query: gql`
        query {
          header(uid: "header", lang: "en-gb") {
            navigation {
              ... on HeaderNavigation {
                navigation_text
                navigation_link {
                  ... on Article {
                    _meta {
                      uid
                    }
                  }
                  ... on Page {
                    _meta {
                      uid
                    }
                  }
                  ... on _ExternalLink {
                    url
                    target
                  }
                }
              }
            }
            button_link {
              ... on Article {
                _meta {
                  uid
                }
              }
              ... on Page {
                _meta {
                  uid
                }
              }
              ... on _ExternalLink {
                url
                target
              }
            }
            button_text
          }
        }
      `,
      update: data => data.header
    }
  }
};
</script>

<style lang="scss" scoped>
header {
  height: 72px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 48px;
  border-bottom: 1px solid $green1;
  box-sizing: border-box;
  position: fixed;
  z-index: 2;
  background-color: $purple1;

  @include tablet {
    justify-content: center;
  }

  a {
    height: 75%;

    img {
      height: 100%;
    }
  }

  @include tablet {
    > .prismic-link.no-highlight {
      display: none;
    }
  }

  nav {
    .prismic-link {
      font-family: $font-secondary;
      font-size: rem-calc(18);
      font-weight: bold;
      text-decoration: none;
      margin: 0 12px;
      opacity: 0.4;
      display: inline;

      &.active,
      &:active,
      &:hover {
        opacity: 1;
      }
    }

    @include tablet {
      display: none;
      position: absolute;
      top: 72px;
      width: 100%;
      z-index: 2;
      background-color: $purple1;
      border-bottom: 1px solid $green1;
      padding: 12px 0;

      &.open {
        display: block;
      }

      .prismic-link {
        display: block;
        text-align: center;
        padding: 12px 0;
      }
    }
  }

  span {
    padding: 24px;
    display: none;
    position: absolute;
    right: 0;
    cursor: pointer;

    @include tablet {
      display: block;
    }
  }
}
</style>
