<template>
  <section>
    <h3>{{ title }}</h3>
    <div
      class="details"
      v-if="currentVideo && !playVideo"
      :style="{
        backgroundImage: currentVideo.video.thumbnail_url
          ? `url(${largeThumbUrl(currentVideo.video)}`
          : null
      }"
    >
      <div>
        <div>
          <div>
            <span v-if="currentVideo.video.duration">{{
              `${Math.floor(currentVideo.video.duration / 60)}:${
                currentVideo.video.duration % 60
              }`
            }}</span>
            <h4 v-if="currentVideo.video.title">
              {{ currentVideo.video.title }}
            </h4>
            <p v-if="currentVideo.video.description">
              {{ currentVideo.video.description }}
            </p>
          </div>
          <div>
            <app-button @click.native="playVideo = true"
              ><img src="~@/assets/icons/play.svg" alt=">"
            /></app-button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="video"
      v-if="playVideo && currentVideo"
      v-html="currentVideo.video.html"
    />
    <div
      class="videos"
      ref="videos"
      :style="{ display: videos.length < 2 ? 'none' : null }"
    >
      <div
        v-for="(obj, index) in videos"
        :key="`video${index}`"
        @click="selectVideo($event, obj.video)"
      >
        <img
          :src="obj.video.video.thumbnail_url"
          :alt="obj.video.video.title"
        />
      </div>
    </div>
  </section>
</template>

<script>
import AppButton from "@/components/AppButton";

export default {
  name: "VideoGallery",
  components: {
    AppButton
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    videos: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentVideo: null,
      playVideo: false
    };
  },
  methods: {
    selectVideo(event, video) {
      this.$refs.videos.childNodes.forEach(child => (child.className = ""));

      if (event) {
        event.target.parentNode.className = "selected";
      }

      this.playVideo = false;

      this.currentVideo = video;
    },
    largeThumbUrl(video) {
      return video.thumbnail_url
        .replace(video.thumbnail_width, "1280")
        .replace(video.thumbnail_height, "720");
    }
  },
  mounted() {
    if (this.videos.length) {
      this.selectVideo(
        { target: this.$refs.videos.childNodes[0].childNodes[0] },
        this.videos[0].video
      );
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  margin: 120px 0;

  @include mobile {
    margin: 64px 0;
  }

  h3 {
    text-align: center;
  }

  .video {
    display: block;
    margin: 0 auto;
    width: 60%;
    height: 425px;

    ::v-deep iframe {
      display: block;
      width: 100%;
      height: 100%;
    }

    @include tablet {
      width: calc(100% - 96px);
      height: 400px;
    }

    @include mobile {
      width: 100%;
      height: 220px;
    }
  }

  .details {
    position: relative;
    width: 60%;
    margin: 0 auto;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
    overflow: hidden;

    @include tablet {
      width: calc(100% - 96px);
    }

    @include mobile {
      width: 100%;
    }

    img {
      width: 100%;
    }

    > div {
      padding-top: 56.25%; // 16:9 aspect ratio
      height: 0;

      > div {
        position: absolute;
        bottom: 0;
        padding: 24px;
        background-image: linear-gradient(
          to bottom,
          rgba($purple2, 0.6),
          $purple2
        );
        display: flex;
        width: 100%;
        box-sizing: border-box;
        justify-content: space-between;

        > div {
          span {
            float: right;
            letter-spacing: rem-calc(2);
            color: $green1;
            font-weight: bold;
          }

          h4 {
            margin: 0;
            color: white;
          }

          p {
            margin-bottom: 0;
          }

          button {
            padding: 22px 19px 22px 25px;
            line-height: initial;
            border-radius: 50%;
            display: block;
            width: 64px;
            height: 64px;
            margin-left: 28px;
          }

          &:first-of-type {
            flex: 1;
          }
        }

        @include mobile {
          padding: 0;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          background-image: none;

          > div {
            button {
              margin-left: 0;
            }

            &:first-of-type {
              display: none;
            }
          }
        }
      }
    }
  }

  .videos {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 32px;

    > div {
      width: 12%;
      margin: 12px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      background-color: $purple2;
      opacity: 0.3;

      &.selected,
      &:hover {
        opacity: 1;
      }

      @include tablet {
        width: 25%;
        margin: 8px;
      }

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}
</style>
