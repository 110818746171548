<template>
  <container>
    <template v-for="(block, index) in parsedBlocks">
      <div :key="index" v-if="block.type == 'image'" class="image">
        <img :src="block.url" />
      </div>
      <ul :key="index" v-else-if="block.type == 'ul'">
        <li
          v-for="(item, itemIndex) in block.items"
          :key="`${index}_${itemIndex}`"
          v-html="item.text"
        />
      </ul>
      <ol :key="index" v-else-if="block.type == 'ol'">
        <li
          v-for="(item, itemIndex) in block.items"
          :key="`${index}_${itemIndex}`"
          v-html="item.text"
        />
      </ol>
      <h1 :key="index" v-else-if="block.type == 'heading1'">
        {{ block.text }}
      </h1>
      <h2 :key="index" v-else-if="block.type == 'heading2'">
        {{ block.text }}
      </h2>
      <h3 :key="index" v-else-if="block.type == 'heading3'">
        {{ block.text }}
      </h3>
      <h4 :key="index" v-else-if="block.type == 'heading4'">
        {{ block.text }}
      </h4>
      <h5 :key="index" v-else-if="block.type == 'heading5'">
        {{ block.text }}
      </h5>
      <h6 :key="index" v-else-if="block.type == 'heading6'">
        {{ block.text }}
      </h6>
      <p
        :key="index"
        v-else-if="block.type == 'paragraph'"
        v-html="block.text"
      ></p>
      <div :key="index" v-else v-html="block.text" />
    </template>
  </container>
</template>

<script>
import Container from "@/components/Container";

export default {
  name: "PrismicText",
  components: {
    Container
  },
  props: {
    content: {
      type: Object,
      default: () => {
        primary: {
          text: [];
        }
      }
    }
  },
  computed: {
    parsedBlocks() {
      // This parses the text within each block, injecting markup from Prismic as defined in "spans"
      const blocks = this.content.primary.text.map(block => {
        if (block.spans && block.spans.length) {
          // Reverse the spans since character indexes will change as we inject
          block.spans.sort((a, b) => b.start - a.start);

          block.spans.forEach((span, index) => {
            let type, attrs;

            if (span.type == "hyperlink") {
              type = "a";
            } else if (!block.type.startsWith("heading")) {
              // no styling in headers since it's messy
              type = span.type;
            }

            if (!type) {
              return;
            }

            if (span.data) {
              attrs = ` href="${span.data.url}" target="${
                span.data.target || ""
              }"`;
            } else {
              attrs = "";
            }

            block.text = [
              block.text.slice(0, span.start),
              `<${type}${attrs}>`,
              block.text.slice(span.start, span.end),
              `</${type}>`,
              block.text.slice(span.end)
            ].join("");

            delete block.spans[index]; // Prevent span being re-applied
          });
        }

        return block;
      });

      // This finds list items and wraps them in a ul or ol
      const grouped = [];

      let subgroup = {};

      blocks.forEach(block => {
        if (block.type == "list-item") {
          subgroup.type = "ul";

          if (!subgroup.items) {
            subgroup.items = [block];
          } else {
            subgroup.items.push(block);
          }
        } else if (block.type == "o-list-item") {
          subgroup.type = "ol";

          if (!subgroup.items) {
            subgroup.items = [block];
          } else {
            subgroup.items.push(block);
          }
        } else {
          if (Object.keys(subgroup).length) {
            grouped.push(subgroup);

            subgroup = {};
          }

          grouped.push(block);
        }
      });

      if (
        this.content.primary.text.length &&
        ["list-item", "o-list-item"].includes(
          this.content.primary.text[this.content.primary.text.length - 1].type
        )
      ) {
        grouped.push(subgroup);
      }

      return grouped;
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  margin: 120px auto;

  @include mobile {
    margin-top: 40px;
  }

  .image {
    text-align: center;

    img {
      width: 80%;

      @include mobile {
        width: 100%;
      }
    }
  }

  p {
    min-height: 0.1px;
  }
}
</style>
