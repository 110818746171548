var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hub",class:_vm.uid},[(_vm.tags.length > 1)?_c('div',{staticClass:"tags"},_vm._l((['All'].concat(_vm.tags)),function(tag,index){return _c('span',{key:`tag${index}`,class:{ selected: _vm.selectedTag == tag },on:{"click":function($event){_vm.selectedTag = tag}}},[_vm._v(_vm._s(tag))])}),0):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(!_vm.$apollo.loading)?_c('div',[_c('div',{staticClass:"cards"},[_vm._l((_vm.documents),function(doc,index){return _c('prismic-link',{key:`doc${index}`,attrs:{"link":_vm.docLink(doc)}},[_c('div',[_c('div',{staticClass:"top",style:({
                backgroundImage: _vm.docImage(doc)
                  ? `url(${_vm.docImage(doc)})`
                  : null
              })}),_c('div',[(_vm.docDate(doc))?_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.docDate(doc)))+" ")]):_vm._e(),_c('div',{staticClass:"tag"},[_vm._v(_vm._s(_vm.docTag(doc)))]),(_vm.docTitle(doc))?_c('h3',[_vm._v(" "+_vm._s(_vm.docTitle(doc))+" ")]):_vm._e(),(_vm.docAuthor(doc))?_c('div',[_vm._v(_vm._s(_vm.docAuthor(doc)))]):_vm._e(),(
                  doc.__typename == 'Update' &&
                  doc.link.__typename == '_ExternalLink'
                )?_c('img',{attrs:{"src":require("@/assets/icons/link-out.svg"),"alt":""}}):_vm._e()])])])}),_c('div'),_c('div'),_c('div')],2),_c('div',{staticClass:"pagination"})]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }