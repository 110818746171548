<template>
  <component
    :is="tag"
    :to="to"
    :href="href"
    :target="target"
    class="prismic-link"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: "PrismicLink",
  props: {
    link: {
      type: [Object, null],
      default: () => {}
    }
  },
  computed: {
    tag() {
      if (!this.link) {
        return "div";
      }

      return this.link._meta ? "router-link" : "a";
    },
    to() {
      if (!this.link) {
        return null;
      }

      if (this.link.__typename == "Article") {
        return `/article/${this.link._meta.uid}`;
      }

      if (this.link.__typename == "Page") {
        return `/${this.link._meta.uid}`;
      }

      return this.link.url;
    },
    href() {
      return this.tag == "div" ? null : this.to;
    },
    target() {
      if (!this.link) {
        return null;
      }

      return this.link.target;
    }
  }
};
</script>
