<template>
  <div class="standard-header" :class="{ 'has-image': content.header_logo }">
    <div
      v-if="content.header_background"
      class="header-background"
      :style="{
        backgroundImage: content.header_background
          ? `url(${content.header_background.url})`
          : null
      }"
    />
    <container>
      <div>
        <div
          class="image"
          :style="{
            backgroundImage: content.header_logo
              ? `url(${content.header_logo.url})`
              : null
          }"
        />
        <span v-if="content.header_tag">{{ content.header_tag[0].text }}</span>
        <h1 v-if="content.header_title">{{ content.header_title[0].text }}</h1>
        <p v-if="content.header_subtitle">
          {{ content.header_subtitle[0].text }}
        </p>
        <div
          class="byline"
          v-if="
            content.author_link || content.author_name || content.publish_date
          "
        >
          <span class="author" v-if="content.author_link && content.author_name"
            >Written by
            <prismic-link :link="content.author_link">{{
              content.author_name[0].text
            }}</prismic-link></span
          >
          <span class="date">{{ formatDate(content.publish_date) }}</span>
        </div>
        <slot />
      </div>
    </container>
  </div>
</template>

<script>
import Container from "@/components/Container";

export default {
  name: "StandardHeader",
  components: {
    Container
  },
  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      return `${date.getDate().toString().padStart(2, "0")}.${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}.${date.getFullYear()}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.standard-header {
  ::v-deep {
    section {
      position: relative;
      text-align: center;
      z-index: 1;
      padding: 64px 0;
      width: auto;

      @include mobile {
        padding: 32px 0;
      }

      > div {
        max-width: 888px;
        width: calc(100% - 32px);
        margin-left: auto;
        margin-right: auto;
      }

      p {
        margin-top: 24px;
        margin-bottom: 0;

        @include mobile {
          margin-top: 8px;
        }
      }

      > span {
        color: $green1;
        letter-spacing: rem-calc(2);
        text-transform: uppercase;
        font-weight: 600;
        display: block;
        margin-bottom: 24px;
      }

      .byline {
        margin-top: 32px;

        span {
          margin: 0 1rem;

          &.date {
            font-weight: bold;
            letter-spacing: 1.78px;

            @include mobile {
              margin-top: 8px;
            }
          }

          @include mobile {
            display: block;
          }
        }
      }

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
      }

      &::before {
        background-color: $purple2;
        background-image: linear-gradient(
          to bottom,
          rgba(black, 0.2) 0%,
          rgba(black, 0.1) 50%,
          rgba(black, 0) 100%
        );
      }

      &::after {
        background-image: linear-gradient(
          to bottom left,
          rgba($purple2, 0) 0%,
          rgba($purple2, 0) 50%,
          rgba($purple2, 1) 50%,
          rgba($purple2, 1) 100%
        );
      }
    }
  }

  &.has-image {
    ::v-deep {
      section {
        p {
          margin-top: 12px;
          max-width: 60%;

          @include mobile {
            max-width: initial;
          }
        }

        > span {
          margin-bottom: 12px;
          font-size: rem-calc(14);
        }

        .image {
          float: right;
          background-color: $purple2;
          border-radius: 4px;
          width: 204px;
          height: 136px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          margin-left: 24px;
          margin-bottom: 16px;

          @include mobile {
            float: none;
            margin-left: auto;
            margin-right: auto;
            width: 117px;
            height: 78px;
          }
        }

        > * {
          text-align: left;

          @include mobile {
            text-align: center;
          }
        }
      }
    }
  }
}

article.has-image {
  .standard-header {
    max-width: 1024px;
    margin: 200px auto 0 auto;

    @include tablet {
      margin-top: 96px;
    }
  }

  .header-background {
    position: absolute;
    height: 320px;
    width: 100%;
    background-size: cover;
    background-position: center;
    top: 0;
    left: 0;

    @include tablet {
      height: 96px;
    }
  }

  > :not(.standard-header) {
    ::v-deep {
      section {
        max-width: 660px;
      }

      section,
      a {
        color: $purple2;
      }
    }
  }
}
</style>
