var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"standard-header",class:{ 'has-image': _vm.content.header_logo }},[(_vm.content.header_background)?_c('div',{staticClass:"header-background",style:({
      backgroundImage: _vm.content.header_background
        ? `url(${_vm.content.header_background.url})`
        : null
    })}):_vm._e(),_c('container',[_c('div',[_c('div',{staticClass:"image",style:({
          backgroundImage: _vm.content.header_logo
            ? `url(${_vm.content.header_logo.url})`
            : null
        })}),(_vm.content.header_tag)?_c('span',[_vm._v(_vm._s(_vm.content.header_tag[0].text))]):_vm._e(),(_vm.content.header_title)?_c('h1',[_vm._v(_vm._s(_vm.content.header_title[0].text))]):_vm._e(),(_vm.content.header_subtitle)?_c('p',[_vm._v(" "+_vm._s(_vm.content.header_subtitle[0].text)+" ")]):_vm._e(),(
          _vm.content.author_link || _vm.content.author_name || _vm.content.publish_date
        )?_c('div',{staticClass:"byline"},[(_vm.content.author_link && _vm.content.author_name)?_c('span',{staticClass:"author"},[_vm._v("Written by "),_c('prismic-link',{attrs:{"link":_vm.content.author_link}},[_vm._v(_vm._s(_vm.content.author_name[0].text))])],1):_vm._e(),_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.formatDate(_vm.content.publish_date)))])]):_vm._e(),_vm._t("default")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }