<template>
  <section :style="{ backgroundImage: `url(${backgroundUrl})` }">
    <h3>{{ title }}</h3>
    <p>{{ subtitle }}</p>
    <prismic-link :link="buttonLink">
      <app-button class="secondary">{{ buttonText }}</app-button>
    </prismic-link>
  </section>
</template>

<script>
import AppButton from "@/components/AppButton";
import PrismicLink from "@/components/PrismicLink";

export default {
  name: "Promo",
  components: {
    AppButton,
    PrismicLink
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    buttonLink: {
      type: Object,
      default: () => {}
    },
    buttonText: {
      type: String,
      default: ""
    },
    backgroundUrl: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 88px 16px;
  background-color: #236296;
  background-size: cover;
  background-position: 50%;

  @include tablet {
    padding: 32px 16px;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 28px;
  }

  p {
    margin-bottom: 82px;
    text-align: center;

    @include tablet {
      margin-bottom: 40px;
    }
  }

  a {
    button {
      width: 100%;
    }

    @include mobile {
      width: 100%;

      button {
        padding: 0;
      }
    }
  }
}
</style>
