<template>
  <section class="hub" :class="uid">
    <div class="tags" v-if="tags.length > 1">
      <span
        v-for="(tag, index) in ['All'].concat(tags)"
        :key="`tag${index}`"
        @click="selectedTag = tag"
        :class="{ selected: selectedTag == tag }"
        >{{ tag }}</span
      >
    </div>
    <transition name="fade">
      <div v-if="!$apollo.loading">
        <div class="cards">
          <prismic-link
            v-for="(doc, index) in documents"
            :key="`doc${index}`"
            :link="docLink(doc)"
          >
            <div>
              <div
                class="top"
                :style="{
                  backgroundImage: docImage(doc)
                    ? `url(${docImage(doc)})`
                    : null
                }"
              ></div>
              <div>
                <div class="date" v-if="docDate(doc)">
                  {{ formatDate(docDate(doc)) }}
                </div>
                <div class="tag">{{ docTag(doc) }}</div>
                <h3 v-if="docTitle(doc)">
                  {{ docTitle(doc) }}
                </h3>
                <div v-if="docAuthor(doc)">{{ docAuthor(doc) }}</div>
                <img
                  v-if="
                    doc.__typename == 'Update' &&
                    doc.link.__typename == '_ExternalLink'
                  "
                  src="~@/assets/icons/link-out.svg"
                  alt=""
                />
              </div>
            </div>
          </prismic-link>
          <!-- These three divs are needed to left-align trailing items since flex doesn't support that -->
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="pagination"></div>
      </div>
    </transition>
  </section>
</template>

<script>
import FormatMixin from "@/mixins/Format";
import PrismicLink from "@/components/PrismicLink";
import PrismicCardMixin from "@/mixins/PrismicCard";
import gql from "graphql-tag";

export default {
  components: {
    PrismicLink
  },
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    uid: {
      type: String,
      default: ""
    }
  },
  mixins: [FormatMixin, PrismicCardMixin],
  data() {
    return {
      documents: [],
      selectedTag: window.decodeURI(this.$route.hash.slice(1)) || "All",
      currentPage: 1,
      perPage: 12
    };
  },
  watch: {
    selectedTag(value) {
      this.$router.replace({ ...this.$route, hash: value });
    }
  },
  apollo: {
    documents: {
      query: gql`
        query ($tags: [String!]) {
          _allDocuments(tags_in: $tags, sortBy: meta_lastPublicationDate_DESC, first: 100) {
            edges {
              node {
                __typename
                ... on Update {
                  headline
                  author {
                    ... on Founding_partner {
                      name
                    }
                  }
                  external_author
                  thumbnail {
                    ... on _ImageLink {
                      url
                    }
                  }
                  background {
                    ... on _ImageLink {
                      url
                    }
                  }
                  date
                  type
                  link {
                    __typename
                    ... on Article {
                      _meta {
                        uid
                      }
                    }
                    ... on _ExternalLink {
                      url
                      target
                    }
                  }
                }
                ... on Article {
                  _meta {
                    uid
                    lastPublicationDate
                  }
                  body {
                    ... on ArticleBodyStandard_header {
                      primary {
                        header_title
                        header_background {
                          ... on _ImageLink {
                            url
                          }
                        }
                        header_logo {
                          ... on _ImageLink {
                            url
                          }
                        }
                        author_name
                        publish_date
                      }
                    }
                  }
                }
                ... on Investor_partner {
                  name
                  link {
                    ... on Article {
                      _meta {
                        uid
                      }
                    }
                    ... on _ExternalLink {
                      url
                      target
                    }
                  }
                  article {
                    ... on Article {
                      _meta {
                        uid
                      }
                    }
                  }
                  logo {
                    ... on _ImageLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          tags: this.selectedTag == "All" ? this.tags : [this.selectedTag]
        };
      },
      update(data) {
        return data._allDocuments.edges
          .map(edge => edge.node)
          .sort((a, b) =>
            new Date(this.docDate(a)) < new Date(this.docDate(b)) ? 1 : -1
          );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  margin: 0 48px;

  .tags {
    span {
      cursor: pointer;
      text-transform: capitalize;
      display: inline-block;
      padding: 20px;
      font-size: rem-calc(20);
      font-weight: 600;
      border-bottom: 1px solid white;
      opacity: 0.4;

      &.selected,
      &:hover {
        opacity: 1;
      }
    }
  }

  .cards {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > * {
      text-decoration: none;
      flex-direction: column;
      justify-content: space-between;
      vertical-align: top;
      margin-bottom: 24px;
      width: calc(25% - 18px);
      background-color: $purple3;
      border-radius: 4px;
      overflow: hidden;

      @include tablet {
        width: calc(50% - 18px);
      }

      @include mobile {
        width: 100%;
      }

      > div {
        > div:not(.top) {
          padding: 16px;
          min-height: 180px;
        }
      }

      .top {
        height: 223px;
        background-position: center;
        background-size: contain;
        background-color: white;
        background-repeat: no-repeat;
      }

      .tag {
        text-transform: uppercase;
        color: $green1;
        font-weight: bold;
        letter-spacing: rem-calc(2);
      }

      h3 {
        margin: 8px 0;
        text-align: left;
        font-size: rem-calc(20);
        font-weight: 600;
      }

      .date {
        font-weight: bold;
        float: right;
      }
    }

    > div {
      background-color: transparent;
    }
  }

  .pagination {
    margin: 20px auto 80px auto;
  }
}

.hub.news-hub .cards > * .top {
  background-size: cover;
}
</style>
