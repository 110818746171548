<template>
  <standard-header
    v-if="$route.query.success"
    :content="{
      header_title: [
        {
          text: 'Submitted'
        }
      ],
      header_subtitle: [
        {
          text: 'We have successfully received your information.'
        }
      ]
    }"
  />
  <standard-header
    v-else-if="$route.query.error"
    :content="{
      header_title: [
        {
          text: 'Something went wrong'
        }
      ],
      header_subtitle: [
        {
          text: 'Please try again later.'
        }
      ]
    }"
  />
  <standard-header v-else :content="content">
    <container>
      <form
        action="//go.fairr.org/l/811253/2020-11-25/6xr2z"
        method="post"
        class="validate"
      >
        <input type="text" name="name" required placeholder="Name*" />
        <input
          type="email"
          name="email"
          required
          placeholder="Email address*"
        />
        <input
          type="text"
          name="title"
          required
          placeholder="Corporate title*"
        />
        <input
          type="text"
          name="company"
          required
          placeholder="Company name*"
        />
        <input type="number" name="aum" required placeholder="AUM ($USD)*" />
        <div>
          <div>Please select one relevent option below*:</div>
          <input
            type="radio"
            name="investment_type"
            id="investment-type-asset-manager"
            value="Investment Manager"
            required
            @change="investmentType"
          />
          <label for="investment-type-asset-manager">
            Investment Manager
          </label>
          <input
            type="radio"
            name="investment_type"
            id="investment-type-asset-owner"
            value="Asset Owner"
            required
            @change="investmentType"
          />
          <label for="investment-type-asset-owner"> Asset Owner </label>
          <input
            type="radio"
            name="investment_type"
            id="investment-type-other"
            value="other"
            required
            @change="investmentType"
          />
          <label for="investment-type-other"> Other Stakeholder </label>
          <transition name="fade">
            <input
              v-if="investmentTypeOther"
              type="text"
              name="investment_type"
              required
              placeholder="Please specify*"
              ref="investmentTypeSpecify"
            />
          </transition>
        </div>
        <div>
          <div>Please select if applicable:</div>
          <input
            type="checkbox"
            name="fairr_member"
            id="fairr-member"
            value="1"
          />
          <label for="fairr-member">Member of FAIRR</label>
          <input
            type="checkbox"
            name="atmf_member"
            id="atmf-member"
            value="1"
          />
          <label for="atmf-member">Member of ATMF</label>
        </div>
        <div>
          <input type="checkbox" name="accept" id="accept" value="1" />
          <label for="accept">
            I agree to be contacted by Investor Action on AMR Founding Collaborators and Investor Partners.
          </label>
        </div>
        <app-button type="submit" name="subscribe">Submit</app-button>
        <div>* Required fields</div>
      </form>
    </container>
  </standard-header>
</template>

<script>
import AppButton from "@/components/AppButton";
import StandardHeader from "@/components/StandardHeader";
import Container from "@/components/Container";
import gql from "graphql-tag";

export default {
  name: "RegisterHeader",
  components: {
    AppButton,
    StandardHeader,
    Container
  },
  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      investmentTypeOther: false
    };
  },
  methods: {
    investmentType(event) {
      this.investmentTypeOther = event && event.target.value == "other";
    }
  }
};
</script>

<style lang="scss" scoped>
.standard-header {
  section {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-top: 24px;
      margin-bottom: 0;

      @include mobile {
        margin-top: 8px;
      }
    }

    > * {
      position: relative;
      z-index: 1;
    }

    form {
      margin-top: 32px;
      width: 50%;

      @include mobile {
        width: 100%;

        button {
          width: 100%;
        }
      }

      > div {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid rgba(white, 0.2);
        padding-top: 16px;
        margin-top: 16px;

        input {
          width: 100%;
          margin-top: 16px;
          margin-bottom: 0;
        }

        label {
          margin-right: 16px;
        }

        > div {
          width: 100%;
          text-align: left;
          margin-bottom: 16px;
        }

        &:last-of-type {
          border-top: 0 none;
        }
      }

      input {
        margin: 8px 0;
      }

      > input {
        width: 100%;
      }

      label {
        display: block;
        text-transform: uppercase;
      }

      input[name="accept"] + label {
        text-transform: none;
      }

      button {
        margin-top: 16px;
      }
    }
  }
}
</style>
