<template>
  <div>
    <div class="video-overlay">
      <video
        v-if="content.header_background"
        loop="loop"
        muted="muted"
        autoplay="autoplay"
      >
        <source type="video/mp4" :src="content.header_background.url" />
      </video>
    </div>
    <div class="header">
      <container>
        <span v-if="content.header_tag">{{ content.header_tag[0].text }}</span>
        <h1 v-if="content.header_title">{{ content.header_title[0].text }}</h1>
        <p v-if="content.header_subtitle">
          {{ content.header_subtitle[0].text }}
        </p>
      </container>
      <founding-partners
        class="founding-partners"
        :founding-partners="foundingPartners"
      />
    </div>
  </div>
</template>

<script>
import Container from "@/components/Container";
import FoundingPartners from "@/components/FoundingPartners";
import gql from "graphql-tag";

export default {
  name: "LandingHeader",
  components: {
    Container,
    FoundingPartners
  },
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    foundingPartners: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.video-overlay {
  position: absolute;
  width: 100%;

  video {
    width: 100%;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba($purple1, 0.7) 70%,
      rgba($purple1, 1) 100%
    );
  }
}

article {
  > div:not(.header, .video-overlay) {
    ::v-deep > section {
      position: relative;
    }
  }
}

.header {
  padding: 64px 0;
  position: relative;
  text-align: center;
  position: relative;
  overflow: hidden;

  h1 {
    font-size: rem-calc(49);

    @include mobile {
      font-size: rem-calc(29);
    }
  }

  p {
    font-size: rem-calc(20);
    font-weight: 600;

    @include mobile {
      font-size: rem-calc(18);
    }
  }

  section {
    width: 80%;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    ::v-deep &.founding-partners {
      .prismic-link {
        background-color: transparent;

        img {
          width: 75%;
        }

        @include tablet {
          margin: 0;
          width: 50%;
        }
      }
    }
  }

  span {
    color: $green1;
    letter-spacing: rem-calc(2);
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    margin-bottom: 24px;

    @include mobile {
      display: none;
    }
  }

  @include mobile {
    padding: 32px 0;
  }
}
</style>
