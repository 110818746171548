export default {
  methods: {
    docTag(doc) {
      return doc.type || doc.__typename.replace("_", " ");
    },
    docDate(doc) {
      return doc.date
        ? doc.date
        : doc.body
        ? doc.body[0].primary.publish_date
        : doc._meta
        ? doc._meta.lastPublicationDate
        : null;
    },
    docBackground(doc) {
      return doc.background
        ? doc.background.url
        : doc.body && doc.body[0].primary.header_background
        ? doc.body[0].primary.header_background.url
        : null;
    },
    docThumbnail(doc) {
      return doc.thumbnail
        ? doc.thumbnail.url
        : doc.logo
        ? doc.logo.url
        : doc.body && doc.body[0].primary.header_logo
        ? doc.body[0].primary.header_logo.url
        : null;
    },
    docImage(doc) {
      return this.docBackground(doc) || this.docThumbnail(doc);
    },
    docLink(doc) {
      return doc.article || doc.link || doc;
    },
    docAuthor(doc) {
      return doc.author
        ? doc.author.name[0].text
        : doc.body
        ? doc.body[0].primary.author_name
        : doc.external_author;
    },
    docTitle(doc) {
      return doc.headline
        ? doc.headline[0].text
        : doc.body
        ? doc.body[0].primary.header_title[0].text
        : doc.name
        ? doc.name[0].text
        : null;
    }
  }
};
