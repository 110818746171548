<template>
  <article>
    <h1>Not Found</h1>
  </article>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style lang="scss" scoped>
article {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
