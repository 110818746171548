<template>
  <div id="app">
    <cookie-bar />
    <app-header />
    <main>
      <transition name="fade" mode="out-in" appear>
        <router-view :key="$route.path" />
      </transition>
    </main>
    <app-footer />
  </div>
</template>

<script>
import AppFooter from "@/components/AppFooter";
import AppHeader from "@/components/AppHeader";
import CookieBar from "@/components/CookieBar";

export default {
  name: "App",
  components: {
    AppFooter,
    AppHeader,
    CookieBar
  },
  metaInfo: {
    titleTemplate: title =>
      title
        ? `${title} | Investor Action on Antimicrobial Resistance`
        : "Investor Action on Antimicrobial Resistance",
    meta: [
      {
        name: "google-site-verification",
        content: "Xd7pI7AAjQ_anWwyfq_me2t5JmLlV5P5MGE2LFQ-4K4"
      }
    ]
  }
};
</script>

<style lang="scss">
body {
  margin: 0;
}

#app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: $purple1;
  color: #fff;
  font-family: $font-body;
  font-size: 16px;

  main {
    margin-top: 72px;
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: stretch;

    article {
      width: 100%;

      > div:not(.article-header):not(.header) {
        > section:first-of-type {
          margin-top: 60px;

          @include mobile {
            margin-top: 24px;
          }
        }

        section {
          position: relative;
        }
      }
    }
  }
}

// Fade in/out transitions for pages
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

a {
  color: white;
  font-weight: bold;
}

h1 {
  font-family: $font-primary;
  font-weight: bold;
  font-size: rem-calc(39);
  margin: 0;

  @include mobile {
    font-size: rem-calc(25);
  }
}

h2 {
  font-family: $font-primary;
  font-size: rem-calc(31);
  margin-bottom: rem-calc(15);

  @include mobile {
    font-size: rem-calc(23);
  }
}

h3 {
  font-family: $font-body;
  font-size: rem-calc(31);
  font-weight: normal;
  margin-bottom: rem-calc(22);

  @include mobile {
    font-size: rem-calc(20);
  }
}

h4 {
  font-family: $font-primary;
  font-size: rem-calc(24);
  margin-bottom: rem-calc(26);

  @include mobile {
    font-size: rem-calc(18);
  }
}

p {
  margin-bottom: 24px;
}

ul {
  padding: 0;
  list-style-type: none;

  li {
    position: relative;
    padding-left: 30px;
    margin-left: 20px;

    &::before {
      content: "";
      position: absolute;
      width: 4px;
      height: 4px;
      background-color: #fff;
      border-radius: 50%;
      left: 0;
      top: 10px;
    }
  }
}

input,
button {
  line-height: 48px;
  border-radius: 24px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 2px;
  box-sizing: border-box;
}

input[type="text"],
input[type="number"],
input[type="email"] {
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0 24px;
  margin-right: 24px;

  &::placeholder {
    text-transform: uppercase;
    color: #fff;
    opacity: 1;
  }

  &:focus {
    border-color: #fff;
  }
}

input[type="checkbox"] {
  display: none;

  & + label::before {
    content: "";
  }

  &:checked + label::before {
    content: "\e902";
  }
}

input[type="radio"] {
  & + label::before {
    border-radius: 50%;
  }
}

input[type="radio"] {
  display: none;

  & + label::before {
    content: "";
    border-radius: 50%;
  }

  &:checked + label::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
  }
}

label {
  position: relative;
  text-align: left;
  padding-left: 30px;
  cursor: pointer;

  &::before {
    font-family: $font-icons;
    font-size: rem-calc(10);
    position: absolute;
    width: 12px;
    height: 12px;
    padding: 2px;
    left: 0;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 2px solid rgba(255, 255, 255, 0.5);
  }
}
</style>
