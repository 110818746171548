<template>
  <button>
    <slot />
  </button>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
button {
  text-transform: uppercase;
  border: 0 none;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
  background-color: $green1;
  padding: 0 44px;
  text-align: center;
  position: relative;
  top: 0;
  transition: all 0.6s;
  cursor: pointer;

  &.secondary {
    background-color: white;
    color: $green1;
  }
}

button:hover {
  box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.3);
  top: -8px;
  transition: all 0.3s;
}
</style>
